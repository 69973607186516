<template>
    <div class="contents">
        <h1 class="pagetitle">注文完了</h1>
        <div class="end">
            <p class="end__text">ご注文ありがとうございます！</p>
            <p v-show="flagText" class="end__text">
                お振込ご案内メールを送信いたしましたので、ご確認ください！
            </p>
            <button class="button--text-link" type="button" @click="goToList()">
                商品注文一覧へ戻る
            </button>
        </div>
    </div>
</template>

<script>
    "use strict";

    import common from "@/mixins/common";

    export default {
        "mixins": [
            common,
        ],
        "props": {
            "contents": {
                "required": true,
                "type": Object,
            },
        },
        data() {
            return {
                "flagText": false,
            };
        },
        created() {
            if (this.contents.input.payment_method === "銀行振込（前払い）") {
                this.flagText = true;
            }
            if (this.$route.query.order_id) {
                this.handleStatusUpdate(this.$route.query.order_id);
            }
            this.clearContents();
        },
        "methods": {
            clearContents() {
                // 入力値を初期値に戻す
                for (let key in this.contents.input) {
                    if (key === "account_registration" || key === "receipt" || key === "receipt_include") {
                        this.contents.input[key] = "0";
                    } else if (key === "fee") {
                        this.contents.input[key] = "0";
                    } else {
                        this.contents.input[key] = "";
                    }
                }
                for (let id in this.contents.orderCount) {
                    this.contents.orderCount[id] = 0;
                }
                for (let key in this.contents.user) {
                    this.contents.user[key] = "";
                }
                this.contents.flagSendData = false;
                this.contents.loginData ={};
            },
            goToList() {
                // 商品注文一覧画面へ
                this.clearContents();
                this.$router.push({ "name": "shop_list" });
            },
            handleStatusUpdate(id) {
                // 個別注文データステータス更新
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/orders/guest/${id}`,
                    {
                        "status": "payment",
                    },
                    this.$_requestConfig()
                ).catch(() => {
                    alert("注文に失敗しました。");
                });
            },
        },
    };
</script>
